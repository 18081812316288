import BankInfo from "pages/BankInfo";
import GuideLine from "pages/GuideLine";
import Homepage from "pages/Homepage";
import Identity from "pages/Identity";
import Inquire from "pages/Inquire";
import Login from "pages/Login";
import Mypage from "pages/Mypage";
import Policy from "pages/Policy";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Purchase from "pages/Purchase";
import PurchaseForm from "pages/PurchaseForm";
import Register from "pages/Register";
import Sell from "pages/Sell";
import SellForm from "pages/SellForm";
import TermOfUse from "pages/TermOfUse";
import VerifyPhoneNumber from "pages/VerifyPhoneNumber";

export type RouterType = {
  path: string;
  component: any;
  exact: boolean;
};

export const router: any = [
  {
    path: "/",
    component: Homepage,
    exact: true,
  },
  {
    path: "/identity",
    component: Identity,
    exact: false,
  },
  {
    path: "/inquire",
    component: Inquire,
    exact: false,
  },
  {
    path: "/policy",
    component: Policy,
    exact: false,
  },
  {
    path: "/login",
    component: Login,
    exact: false,
  },
  {
    path: "/register",
    component: Register,
    exact: false,
  },
  {
    path: "/verify-phone-number",
    component: VerifyPhoneNumber,
    exact: false,
  },
  {
    path: "/terms-of-use",
    component: TermOfUse,
    exact: false,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    exact: false,
  },
];

export const privateRouter: any = [
  {
    path: "/purchase",
    component: Purchase,
    exact: false,
  },
  {
    path: "/purchase-form",
    component: PurchaseForm,
    exact: false,
  },
  {
    path: "/sell",
    component: Sell,
    exact: false,
  },
  {
    path: "/sell-form",
    component: SellForm,
    exact: false,
  },
  {
    path: "/bank-info",
    component: BankInfo,
    exact: false,
  },
  {
    path: "/mypage",
    component: Mypage,
    exact: false,
  },
  {
    path: "/guideline",
    component: GuideLine,
    exact: false,
  },
];

// export default router;
