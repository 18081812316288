import { createSlice } from "@reduxjs/toolkit";

export interface PopupManageState {
  isOpenApproveWarning: boolean;
  isOpenLockBuySell: boolean;
  isOpenConfirmPurchase: boolean;
  isOpenRequestWithdraw: boolean;
}

const initialState: PopupManageState = {
  isOpenApproveWarning: false,
  isOpenLockBuySell: false,
  isOpenConfirmPurchase: false,
  isOpenRequestWithdraw: false,
};

export const popupManageSlice = createSlice({
  name: "popupManage",
  initialState,
  reducers: {
    openApproveWarning: (state) => {
      state.isOpenApproveWarning = true;
    },
    closeApproveWarning: (state) => {
      state.isOpenApproveWarning = false;
    },
    openLockBuySell: (state) => {
      state.isOpenLockBuySell = true;
    },
    closeLockBuySell: (state) => {
      state.isOpenLockBuySell = false;
    },
    openConfirmPurchase: (state) => {
      state.isOpenConfirmPurchase = true;
    },
    closeConfirmPurchase: (state) => {
      state.isOpenConfirmPurchase = false;
    },
    openRequestWithdraw: (state) => {
      state.isOpenRequestWithdraw = true;
    },
    closeRequestWithdraw: (state) => {
      state.isOpenRequestWithdraw = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openApproveWarning,
  closeApproveWarning,
  openLockBuySell,
  closeLockBuySell,
  openConfirmPurchase,
  closeConfirmPurchase,
  openRequestWithdraw,
  closeRequestWithdraw,
} = popupManageSlice.actions;

export default popupManageSlice.reducer;
