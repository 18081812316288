import { apiConfig } from "api";
import { ApiUrl } from "constant/endpoint";

interface PurchasePayload {
  inputMoney: number;
  amount: number | string;
  userAddress: string;
  bankName: string;
  accountNumber: string;
  accountName: string;
  rate_buy_coin: string | number;
  rate_krw_usdt: string | number;
}

interface ListBuyPayload {
  perPage: number;
  page: number;
  query: string;
  startTime: string;
  endTime: string;
}

interface GetBuyCoinPayload {
  id_buy_coin: number;
}

export const purchaseApi = (payload: PurchasePayload) => {
  return apiConfig.request({
    url: ApiUrl.CREATE_BUY_COIN,
    method: "POST",
    data: payload,
    tokenRequired: true,
  });
};

export const listBuyApi = (payload: ListBuyPayload) => {
  return apiConfig.request({
    url: ApiUrl.LIST_BUY_COIN,
    method: "POST",
    data: payload,
    tokenRequired: true,
  });
};

export const getBuyCoinApi = (payload: GetBuyCoinPayload) => {
  return apiConfig.request({
    url: ApiUrl.GET_BUY_COIN,
    method: "POST",
    data: payload,
    tokenRequired: true,
  });
};

export const getTraderApi = async () => {
  return apiConfig.request({
    url: ApiUrl.GET_TRADER,
    method: "GET",
    tokenRequired: true,
  });
};
