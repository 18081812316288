export const DOMAIN_NAME = "https://world14.com";
export const SITE_NAME = "world14";
export const SECOND_PER_MINUTE = 60;
export const API_URL = "https://agency-api.world14.com";
export const LIST_AMOUNT = [
  { label: "10,000원", value: 10000 },
  { label: "50,000원", value: 50000 },
  { label: "100,000원", value: 100000 },
  { label: "500,000원", value: 500000 },
  { label: "1,000,000원", value: 1000000 },
  { label: "초기화", value: null },
];
export const SOCKET_URL = "https://ws.world14.com";
