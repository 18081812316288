import { Button, Tabs, TabsProps } from "antd";
import ArrowDown from "assets/images/arrowDown.svg";
import React, { useEffect, useState } from "react";
import DataBuy from "./DataBuy";
import DataSell from "./DataSell";
import * as S from "./style";
import fileDownload from "js-file-download";
import { downloadExcelUserHistoryApi } from "api/my-page";
import VerifyHistory from "./VerifyHistory";
import { useHistory, useLocation } from "react-router-dom";
import DataWithdraw from "./DataWithdraw";
import { AppDispatch } from "app/store";
import { useAppDispatch } from "app/hooks";
import { openRequestWithdraw } from "features/popupManage/popupManageSlice";

function Mypage() {
  const history = useHistory();
  const location = useLocation();
  const [tabKey, setTabKey] = useState<string>("1");
  const dispatch: AppDispatch = useAppDispatch();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "코인구매 신청내역",
      children: tabKey === "1" && <DataBuy />,
    },
    {
      key: "2",
      label: "코인전송 내역",
      children: tabKey === "2" && <DataWithdraw />,
    },
    {
      key: "3",
      label: "코인판매 신청내역",
      children: tabKey === "3" && <DataSell />,
    },
    // {
    //   key: "3",
    //   label: "인증내역",
    //   children: tabKey === "3" && <VerifyHistory />,
    // },
  ];
  const onChange = (k: string) => {
    setTabKey(k);
  };

  const handleDownload = async () => {
    try {
      const resp: any = await downloadExcelUserHistoryApi();
      if (resp) {
        fileDownload(resp, "user-info.xlsx");
      }
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab"); // Get the 'tab' query param if it exists
    console.log("tabParam", tabParam);

    if (tabParam) {
      setTabKey(tabParam.toString());
    }
  }, [location]);

  return (
    <React.Fragment>
      <S.Wrapper>
        <div className="container container-bs sub-page inquire">
          <div className="heading-text heading-section">
            <h2>마이페이지</h2>
          </div>
          <S.Content>
            <Tabs
              // defaultActiveKey={tabKey}
              activeKey={tabKey}
              items={items}
              onChange={onChange}
              className="cs-tab"
            />
            <S.GrpButton>
              {/* {tabKey === "2" && (
                <Button
                  className="btn btn-v01 btn-rounded"
                  style={{
                    fontSize: 14,
                    height: 40,
                    padding: "9px 36px",
                    margin: 0,
                  }}
                  onClick={() => history.push("/sell")}
                >
                  간편판매
                </Button>
              )}
              {tabKey === "3" && (
                <Button
                  className="btn btn-v01 btn-rounded"
                  style={{
                    fontSize: 14,
                    height: 40,
                    padding: "9px 36px",
                    margin: 0,
                  }}
                  onClick={() => dispatch(openRequestWithdraw())}
                >
                  출금
                </Button>
              )} */}
              <S.DownloadButton onClick={handleDownload}>
                정보 다운로드 <img src={ArrowDown} alt="" />
              </S.DownloadButton>
            </S.GrpButton>
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Mypage;
