import * as S from "./style";
import UsdtIcon from "assets/images/USDT_icon.svg";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
} from "antd";
import { getBuyCoinApi, purchaseApi } from "api/purchase";
import { useHistory } from "react-router-dom";
import {
  amountExchange,
  MoneyConvert,
  onNumericInputChange,
} from "utils/format";
import {
  bankInfoApi,
  // getRateKrwUsdtApi,
  userInfoApi,
} from "api/user";
import { useDispatch, useSelector } from "react-redux";
import { openLoading, closeLoading } from "features/loading/loadingSlice";
import {
  openApproveWarning,
  openLockBuySell,
} from "features/popupManage/popupManageSlice";
import { RootState } from "app/store";
import { LIST_AMOUNT } from "constant/configs";
import WalletIcon from "assets/images/Wallet.svg";
import useWindowSize from "hooks/useWindowSize";

function PurchaseForm() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<any>();
  // const [dataRate, setDataRate] = useState<any>();
  const [dataUserInfo, setDataUserInfo] = useState<any>();
  const [dataRespBuyCoin, setDataRespBuyCoin] = useState<any>();
  const [statusArs, setStatusArs] = useState<"PENDING" | "CONFIRM" | "REJECT">(
    "PENDING"
  );
  const { userInfoData, exchangeData }: any = useSelector(
    (state: RootState) => state.userInfo
  );
  const [amount, setAmount] = useState<number>(0);
  const { width }: any = useWindowSize();

  // console.log("dataUserInfo", dataUserInfo);

  useEffect(() => {
    getUserInfo();
    // getDataExchange();
  }, []);

  const getUserInfo = async () => {
    try {
      const resp: any = await userInfoApi();
      if (resp?.result) {
        setDataUserInfo(resp?.data);
        form.setFieldsValue({ userAddress: resp?.data?.address });
        if (resp?.data?.approve === 0) {
          dispatch(openApproveWarning());
        } else {
          if (resp?.data?.lock_buy_coin === 1) {
            dispatch(openLockBuySell());
          }
        }
      }
    } catch (error) {
      console.log("User Info error", error);
    }
  };

  // const getDataExchange = async () => {
  //   try {
  //     const resp: any = await getRateKrwUsdtApi();
  //     if (resp?.result) {
  //       setDataRate(resp?.data);
  //     }
  //   } catch (error) {
  //     console.log("getDataExchange error", error);
  //   }
  // };

  const handleOk = async () => {
    // console.log("formValues.amount", formValues);
    setIsModalOpen(false);
    dispatch(openLoading());
    setStatusArs("PENDING");
    try {
      const bankInfoResp: any = await bankInfoApi();
      if (bankInfoResp?.id) {
        try {
          const resp: any = await purchaseApi({
            amount: amountExchange(
              exchangeData?.rate_buy_coin,
              exchangeData?.rate_krw_usdt,
              formValues.amount,
              "BUY"
            ),
            inputMoney: formValues.amount,
            userAddress: formValues.userAddress,
            bankName: bankInfoResp?.bank_name,
            accountName: bankInfoResp?.account_name,
            accountNumber: bankInfoResp?.account_number,
            rate_buy_coin: exchangeData?.rate_buy_coin,
            rate_krw_usdt: exchangeData?.rate_krw_usdt,
          });
          // console.log("purchaseApi error", resp);
          if (resp?.result) {
            setDataRespBuyCoin(resp?.data);
            if (dataUserInfo?.verify_ars === 0) {
              messageApi.open({
                type: "success",
                content: resp?.message || "성공했습니다.",
                duration: 1,
                onClose: () => {
                  dispatch(closeLoading());
                  history.push({ pathname: "/bank-info", state: resp?.data });
                },
              });
            }
            return;
          }
          messageApi.open({
            type: "error",
            content: resp?.error?.message || "오류입니다.",
          });
          dispatch(closeLoading());
        } catch (error) {
          console.log("handlePurchase error", error);
          dispatch(closeLoading());
        }
      }
    } catch (error) {
      console.log("getBankInfo error", error);
      dispatch(closeLoading());
    }
  };

  const getBuyCoinInfo = async () => {
    try {
      const resp: any = await getBuyCoinApi({
        id_buy_coin: dataRespBuyCoin?.id,
      });
      if (resp?.result) {
        setStatusArs(resp?.data?.status_ars);
        if (resp?.data?.status_ars === "CONFIRM") {
          messageApi.open({
            type: "success",
            content: "인증 완료되었습니다.",
            onClose: () => {
              dispatch(closeLoading());
              history.push({ pathname: "/bank-info", state: dataRespBuyCoin });
            },
          });
          return;
        }
        if (resp?.data?.status_ars === "REJECT") {
          messageApi.open({
            type: "error",
            content: "인증 실패했습니다.",
            onClose: () => {
              dispatch(closeLoading());
            },
          });
          return;
        }
        if (resp?.data?.status_ars === "PENDING") {
          return;
        }
      } else {
        messageApi.open({
          type: "error",
          content: resp?.error?.message || "오류입니다.",
          onClose: () => {
            dispatch(closeLoading());
          },
        });
      }
    } catch (error) {
      console.log("getBuyCoinInfo", error);
      dispatch(closeLoading());
    }
  };

  // console.log("statusArs", statusArs);

  useEffect(() => {
    if (dataRespBuyCoin?.id && statusArs === "PENDING") {
      const intvervalGetRespBuyCoin = setInterval(() => {
        getBuyCoinInfo();
      }, 3000);
      return () => clearInterval(intvervalGetRespBuyCoin);
    }
  }, [dataRespBuyCoin?.id, statusArs]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePurchase = (values: any) => {
    setIsModalOpen(true);
    setFormValues(values);
  };

  // console.log("value", amount);

  return (
    <React.Fragment>
      {contextHolder}
      <Modal
        open={isModalOpen}
        // open={true}
        centered
        width={500}
        title={
          <S.ModalTitle className="modal-title">신청정보 확인</S.ModalTitle>
        }
        style={{
          padding: "32px 28px 20px 28px",
        }}
        onCancel={handleCancel}
        className="cs-common-modal"
        footer={[
          <div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
            <Button
              onClick={handleCancel}
              className="btn btn-v02 btn-rounded"
              style={{
                width: 190,
                height: 50,
                margin: 0,
                fontSize: 18,
                color: "#03000A",
              }}
            >
              취소
            </Button>

            <Button
              onClick={handleOk}
              className="btn btn-v01 btn-rounded no-padding"
              style={{ width: 190, height: 50, margin: 0, fontSize: 18 }}
            >
              확인
            </Button>
          </div>,
        ]}
      >
        <S.ModalContent>
          <p>
            고객님의 코인구매신청 금액은 {MoneyConvert(formValues?.inputMoney)}{" "}
            원 입니다.
          </p>
          <p>해당 코인 구매신청은 '본인' 외에는 불가합니다.</p>
          <p>구매신청을 원하시면 '확인' 을 눌러주십시오.</p>
          {dataUserInfo?.verify_ars === 1 && (
            <p>*확인을 누르시면 ARS인증이 진행됩니다.</p>
          )}
        </S.ModalContent>
      </Modal>
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>간편구매 신청서작성</h2>
            <div className="sub-wrapper">
              <div className="sub-title">
                <p>
                  {" "}
                  입금 : 1회 입금 한도{" "}
                  {MoneyConvert(
                    Number(exchangeData?.limit_buy_amount / 10000) || 0
                  )}
                  만원. 1일 무제한 가능합니다.{" "}
                </p>
                <p> 1회 입금한도 이상 입금시 한도 내 분할 입금 바랍니다.</p>
              </div>
            </div>
          </div>
          <S.Content>
            <div className="custom-form-antd">
              <Form
                form={form}
                name="control-hooks"
                onFinish={handlePurchase}
                layout="vertical"
              >
                <div className="form-wrapper">
                  <div className="usdt-line">
                    {/* <img src={UsdtIcon} alt="" width="28px" />
                    {MoneyConvert(
                      exchangeData?.rate_krw_usdt || 0,
                      true,
                      2
                    )}{" "}
                    oUSDT */}
                    {/* <p className="balance">
                      <img src={UsdtIcon} alt="" width="18px" />{" "}
                      {MoneyConvert(
                        Number(exchangeData?.rate_krw_usdt_auto) || 0,
                        true
                      )}{" "}
                      oUSDT
                    </p> */}
                    <p
                      className="balance detail"
                      style={{
                        border: "1px #ffffff solid",
                        borderRadius: 20,
                        padding: "4px 18px",
                      }}
                    >
                      <img src={WalletIcon} alt="" width="18px" />{" "}
                      {MoneyConvert(
                        Number(userInfoData?.balance) *
                          exchangeData?.rate_krw_usdt || 0,
                        true,
                        0
                      )}
                      KRW {width <= 991 ? <br /> : "/"}{" "}
                      {MoneyConvert(
                        Number(userInfoData?.balance) || 0,
                        true,
                        2
                      )}{" "}
                      oUSDT
                    </p>
                  </div>
                  <div className="list-amount-option">
                    {LIST_AMOUNT.map((item, index) => (
                      <Button
                        key={index}
                        className={`btn ${
                          item?.value === null ? "btn-v01" : "btn-v03"
                        }`}
                        style={{
                          fontSize: 14,
                          height: 40,
                          padding: "9px 20px",
                          marginLeft: 0,
                        }}
                        onClick={() => {
                          if (item?.value === null) {
                            setAmount(0);
                            form.setFieldsValue({ amount: 0 });
                            form.setFieldsValue({ inputMoney: 0 });
                          } else {
                            form.setFieldsValue({
                              amount: amount + item?.value,
                            });
                            form.setFieldsValue({
                              inputMoney: amount + item?.value,
                            });
                            setAmount((prev) => prev + item?.value);
                          }
                        }}
                      >
                        {item?.label}
                      </Button>
                    ))}
                  </div>
                  <Form.Item
                    name="inputMoney"
                    label="구매금액 "
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                      // { required: true, message: "금액을 선택해주세요" },
                    ]}
                  >
                    {/* <InputNumber
                    size="large"
                    style={{ height: "100%", width: "100%" }}
                    addonAfter="원"
                    max={
                      (Number(exchangeData?.limit_buy_amount) || 0) as number
                    }
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      parseFloat(value!.replace(/\$\s?|(,*)/g, ""))
                    }
                    onChange={(value) => {
                      const parsed = onNumericInputChange(value);
                      if (parsed) {
                        form.setFieldsValue({ amount: parsed });
                      }
                    }}
                    controls={false}
                  /> */}
                    {/* <Radio.Group
                      options={LIST_AMOUNT}
                      className="cs-radio"
                      optionType="button"
                      buttonStyle="solid"
                      onChange={({ target: { value } }: RadioChangeEvent) => {
                        // console.log("onChange", value);
                        form.setFieldsValue({ amount: amount + value });
                        setAmount((prev) => prev + value);
                        if (value === false) {
                          setAmount(0);
                          form.setFieldsValue({ amount: 0 });
                        }
                      }}
                    /> */}
                    <InputNumber
                      size="large"
                      style={{ height: 50, width: "100%" }}
                      addonAfter="원"
                      disabled
                      formatter={(value) =>
                        `${value || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                      controls={false}
                    />
                  </Form.Item>
                  <Form.Item
                    label="구매수량"
                    name="amount"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      style={{ height: 50, width: "100%" }}
                      addonAfter="개"
                      disabled
                      formatter={(value) =>
                        `${amountExchange(
                          exchangeData?.rate_buy_coin,
                          exchangeData?.rate_krw_usdt,
                          value || 0,
                          "BUY"
                        )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                      controls={false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="userAddress"
                    label="회원 지갑주소"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input style={{ height: 50, width: "100%" }} disabled />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    size="large"
                    className="btn btn-v01 btn-rounded"
                    htmlType="submit"
                    style={{
                      height: 65,
                      width: 315,
                      fontSize: 18,
                      padding: "27px 18px",
                      marginTop: 48,
                    }}
                  >
                    신청
                  </Button>
                </div>
              </Form>
            </div>
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default PurchaseForm;
