import * as S from "./style";
import React, { useEffect } from "react";
import Guideline1 from "assets/images/pdf/1.jpg";
import Guideline2 from "assets/images/pdf/2.jpg";
import Guideline3 from "assets/images/pdf/3.jpg";
import Guideline4 from "assets/images/pdf/4.jpg";
import Guideline5 from "assets/images/pdf/5.jpg";
import Guideline6 from "assets/images/pdf/6.jpg";
import Guideline7 from "assets/images/pdf/7.jpg";
import Guideline8 from "assets/images/pdf/8.jpg";
import Guideline9 from "assets/images/pdf/9.jpg";
import Guideline10 from "assets/images/pdf/10.jpg";
import Guideline11 from "assets/images/pdf/11.jpg";
import Guideline12 from "assets/images/pdf/12.jpg";

const guidelineList = [
  Guideline1,
  Guideline2,
  Guideline3,
  Guideline4,
  Guideline5,
  Guideline6,
  Guideline7,
  Guideline8,
  Guideline9,
  Guideline10,
  Guideline11,
  Guideline12,
];

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>메뉴얼</h2>
          </div>
          <S.Content className="cms-content">
            {guidelineList?.map((item, index) => (
              <img
                src={item}
                alt={"guideline" + index}
                style={{ width: "100%", height: "auto" }}
              />
            ))}
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
