import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  ModalProps,
} from "antd";
import * as S from "./style";
import {
  MoneyConvert,
  onNumericInputChange,
  truncateDecimal,
} from "utils/format";
import { openLoading, closeLoading } from "features/loading/loadingSlice";
import { userTransferToAdmin } from "api/my-page";
import { getUserInfoData } from "features/userInfo/userInfoSlice";
import { AppDispatch, RootState } from "app/store";
import { useAppDispatch } from "app/hooks";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import UsdtIcon from "assets/images/USDT_icon.svg";
import { useHistory } from "react-router-dom";
import { LIST_AMOUNT } from "constant/configs";
import WalletIcon from "assets/images/Wallet.svg";
import useWindowSize from "hooks/useWindowSize";

interface RequestWithdrawModalProps extends ModalProps {
  handleOk?: () => void;
  handleCancel: () => void;
}

const RequestWithdrawModal: React.FC<RequestWithdrawModalProps> = ({
  handleOk,
  handleCancel,
  ...props
}) => {
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { userInfoData, exchangeData }: any = useSelector(
    (state: RootState) => state.userInfo
  );

  const [value, setValue] = useState<string | number | null>("");
  const [amountKrw, setAmountKrw] = useState<number>(0);
  const { width }: any = useWindowSize();

  useEffect(() => {
    if (userInfoData) {
      // console.log("123213", userInfoData);
      form.setFieldsValue({
        company_address: userInfoData?.admin_address,
      });
    }
  }, [userInfoData]);

  const handleSubmit = async (values: any) => {
    // const cleanedMoney = values.money.replace(/,/g, "").trim(); // Clean the money input
    // console.log(
    //   "values",
    //   values,
    //   cleanedMoney,
    //   (Number(cleanedMoney) / Number(exchangeData?.rate_krw_usdt)).toFixed(2)
    // );
    dispatch(openLoading());
    try {
      const resp: any = await userTransferToAdmin({
        company_address: values?.company_address,
        amount: (
          Number(values.money) / Number(exchangeData?.rate_krw_usdt)
        ).toFixed(2),
        money: values.money,
        rate: exchangeData?.rate_krw_usdt,
      });
      if (resp?.result) {
        dispatch(getUserInfoData());
        messageApi.open({
          type: "success",
          duration: 2,
          content: resp?.message || "성공했습니다.",
          onClose: () => {
            dispatch(closeLoading());
            handleCancel();
            handleOk && handleOk();
            history.push("/mypage?tab=2");
          },
        });
        return;
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message || "오류입니다.",
      });
      dispatch(closeLoading());
    } catch (error) {
      console.log("handlePurchase error", error);
      dispatch(closeLoading());
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        {...props}
        forceRender={true}
        centered
        width={700}
        title={
          <S.ModalTitle className="modal-title" style={{ textAlign: "center" }}>
            코인전송
          </S.ModalTitle>
        }
        style={{
          padding: "32px 28px 20px 28px",
        }}
        className="cs-common-modal"
        footer={false}
      >
        <S.ModalContent>
          <div className="custom-form-antd">
            <Form
              form={form}
              name="control-hooks"
              onFinish={handleSubmit}
              layout="vertical"
            >
              <div className="form-wrapper">
                <div className="usdt-line">
                  {/* <p className="balance">
                    <img src={UsdtIcon} alt="" width="18px" />{" "}
                    {MoneyConvert(
                      Number(exchangeData?.rate_krw_usdt_auto) || 0,
                      true
                    )}{" "}
                    oUSDT
                  </p> */}
                  <p
                    className="balance detail"
                    style={{
                      border: "1px #ffffff solid",
                      borderRadius: 20,
                      padding: "4px 18px",
                    }}
                  >
                    <img src={WalletIcon} alt="" width="18px" />{" "}
                    {MoneyConvert(
                      Number(userInfoData?.balance) *
                        exchangeData?.rate_krw_usdt || 0,
                      true,
                      0
                    )}
                    KRW {width <= 991 ? <br /> : "/"}{" "}
                    {MoneyConvert(Number(userInfoData?.balance) || 0, true, 2)}{" "}
                    oUSDT
                  </p>
                </div>
                <Form.Item
                  name="company_address"
                  label="업체 지갑주소"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <Input style={{ height: 50, width: "100%" }} disabled />
                </Form.Item>
                <Form.Item>
                  <div
                    className="list-amount-option"
                    style={{ marginBottom: 0 }}
                  >
                    {LIST_AMOUNT.map((item, index) => (
                      <Button
                        key={index}
                        className={`btn ${
                          item?.value === null ? "btn-v01" : "btn-v03"
                        }`}
                        style={{
                          fontSize: 14,
                          height: 40,
                          padding: "9px 10px",
                          marginLeft: 0,
                        }}
                        onClick={() => {
                          if (item?.value === null) {
                            setAmountKrw(0);
                            form.setFieldsValue({ amount: 0 });
                            form.setFieldsValue({ money: 0 });
                          } else {
                            form.setFieldsValue({
                              amount: (
                                (amountKrw + item?.value) /
                                exchangeData?.rate_krw_usdt
                              ).toFixed(2),
                            });
                            form.setFieldsValue({
                              money: amountKrw + item?.value,
                            });
                            setAmountKrw((prev) => prev + item?.value);
                          }
                        }}
                      >
                        {item?.label}
                      </Button>
                    ))}
                  </div>
                </Form.Item>
                <Form.Item
                  name="money"
                  label="출금 금액(KRW)"
                  // rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <InputNumber
                    size="large"
                    style={{ height: 50, width: "100%" }}
                    // addonAfter="oUSDT"
                    disabled
                    // formatter={(value) =>
                    //   `${(
                    //     Number(value) / Number(exchangeData?.rate_krw_usdt)
                    //   ).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    // }
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    controls={false}
                  />
                  {/* <div style={{ position: "relative" }}>
                    <InputNumber
                      size="large"
                      style={{ height: "100%", width: "100%" }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) =>
                        parseFloat(value!.replace(/\$\s?|(,*)/g, ""))
                      }
                      value={value}
                      onChange={(value) => {
                        const amount =
                          Number(value) /
                          Number(exchangeData?.rate_krw_usdt || 0);
                        const parsed = onNumericInputChange(amount);
                        setValue(value);
                        console.log(
                          "onNumericInputChange",
                          value,
                          onNumericInputChange(value)
                        );
                        if (parsed) {
                          // form.setFieldsValue({ money: parsed });
                          console.log("amount", value, parsed.toFixed(2));
                          form.setFieldsValue({ amount: parsed.toFixed(2) });
                          // setAmountUSDT(parsed / exchangeData?.rate_krw_usdt);
                        }
                      }}
                      controls={false}
                    />
                    {userInfoData?.balance > 0 && (
                      <Button
                        onClick={() => {
                          const maxMoney = Number(
                            userInfoData?.balance *
                              exchangeData?.rate_krw_usdt || 0
                          ); // Get the maximum value
                          // console.log("maxMoney", maxMoney);
                          const parsedValue = onNumericInputChange(maxMoney); // Use the same parsing logic from onChange
                          const parsedValueUSDT = onNumericInputChange(
                            Number(userInfoData?.balance) || 0
                          ); // Use the same parsing logic from onChange
                          if (parsedValue !== undefined) {
                            form.setFieldsValue({
                              money: parsedValue.toFixed(0), // Set the value to the parsed result
                            });
                          }
                          if (parsedValueUSDT !== undefined) {
                            form.setFieldsValue({
                              amount: parsedValueUSDT.toFixed(2), // Set the value to the parsed result
                            });
                          }
                          setValue(maxMoney.toFixed(0));
                          // setAmountUSDT(
                          //   Number(maxMoney || 0) / exchangeData?.rate_krw_usdt
                          // );

                          // console.log("money", form.getFieldValue("amount"));
                        }}
                        className="btn btn-v01"
                        style={{
                          height: 40,
                          margin: 0,
                          fontSize: "18px",
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      >
                        MAX
                      </Button>
                    )}
                  </div> */}
                </Form.Item>
                <Form.Item
                  label="출금 수량(oUSDT)"
                  name="amount"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <InputNumber
                    size="large"
                    style={{ height: 50, width: "100%" }}
                    // addonAfter="oUSDT"
                    disabled
                    // formatter={(value) =>
                    //   `${(
                    //     Number(value) / Number(exchangeData?.rate_krw_usdt)
                    //   ).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    // }
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    controls={false}
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                  gap: 20,
                }}
              >
                <Button
                  onClick={handleCancel}
                  className="btn btn-v02 btn-rounded"
                  style={{
                    width: 190,
                    height: 50,
                    margin: 0,
                    color: "#03000A",
                    fontSize: "18px",
                  }}
                >
                  취소
                </Button>

                <Button
                  htmlType="submit"
                  className="btn btn-v01 btn-rounded no-padding"
                  style={{ width: 190, height: 50, fontSize: "18px" }}
                >
                  확인
                </Button>
              </div>
            </Form>
          </div>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default RequestWithdrawModal;
