import MainLayout from "layouts/MainLayout";
import { Redirect, Route, Switch } from "react-router-dom";
// import router, { RouterType } from "router";
import { useAuth } from "customHook/useAuth";
import { RouterType, privateRouter, router } from "router";
import { useEffect } from "react";
import {
  getDataExchange,
  getUserInfoData,
} from "features/userInfo/userInfoSlice";
import { useAppDispatch } from "app/hooks";
import { AppDispatch, RootState } from "app/store";
import { useSelector } from "react-redux";

function PrivateRoute({ component: TargetPage, ...rest }: any) {
  const { isLogined } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogined ? <TargetPage {...props} /> : <Redirect to={"/"} />
      }
    />
  );
}

function App() {
  const dispatch: AppDispatch = useAppDispatch();
  const { isLogined } = useAuth();
  const accessToken = useSelector(
    (state: RootState) => state.user.currentUser?.accessToken
  );

  // console.log("currentUser", currentUser);

  useEffect(() => {
    if (accessToken) {
      dispatch(getUserInfoData());
    }
  }, [accessToken]);

  useEffect(() => {
    dispatch(getDataExchange());
  }, []);

  return (
    <Switch>
      <MainLayout>
        <>
          {router.map((r: RouterType, i: number) => {
            return (
              <Route
                key={i}
                exact={r.exact}
                path={r.path}
                component={r.component}
              />
            );
          })}
          {privateRouter.map((r: RouterType, i: number) => {
            return (
              <PrivateRoute
                key={i}
                exact={r.exact}
                path={r.path}
                component={r.component}
              />
            );
          })}
        </>
      </MainLayout>
    </Switch>
  );
}

export default App;
