import styled from "styled-components";

export const Wrapper = styled.div`
  .over-layout-menu {
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.75);
    transition: all 0.4s ease;
    position: fixed;
    display: block;
    z-index: 11;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  #header {
    border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
    .header-inner > .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      #logo {
        float: none;
      }
      #mainMenu {
        > .container {
          @media (min-width: 1025px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          > nav {
            float: none;
          }
          li.menu-item {
            opacity: 0.5;
            &:hover {
              opacity: 1;
            }
            button {
              color: #ffffff;
              font-size: 16px;
              padding: 0;
              background: transparent;
              border: none;
              outline: none;
              padding: 0 12px;
              vertical-align: baseline;
            }

            @media (max-width: 1024px) {
              display: flex;
              button {
                color: #000;
                font-size: 14px;
                font-weight: 600;
                padding: 0 15px;
                height: 43px;
                line-height: 43px;
              }
            }
          }
          li .btn {
            margin: 10px 16px;
          }
        }
      }
      .grp-btn {
        display: flex;
        gap: 16px;
        .btn {
          margin: 0;
        }
      }
    }
    @media (max-width: 1024px) {
      .header-inner #logo {
        position: relative !important;
      }
      #mainMenu-trigger {
        position: absolute !important;
        right: 30px;
      }
    }
  }
`;

export const BoxMenu = styled.div`
  @media (max-width: 1024px) {
    display: block;
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    visibility: hidden;
    z-index: 111;
    width: 300px;
    height: 100%;
    transform: translateX(100%);
    transition: all 0.4s ease;
    background-color: #fff;
    box-shadow: 0 12px 6px rgba(0, 0, 0, 0.06);
    &.open-menu {
      visibility: visible;
      transform: translateX(0);
    }
    > .container {
      width: 100%;
      height: 100%;
      > nav {
        width: 100%;
        height: 100%;
        > ul {
          width: 100%;
          height: 100%;
        }
      }
    }
    .balance {
      margin: 0;
      font-size: 14px;
      color: #000000;
      padding: 0 15px;
    }
  }
  @media (min-width: 1025px) {
    .menu-item.menu-item-active {
      opacity: 1 !important;
    }
  }
`;

export const CloseMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  > a {
    width: 40px;
    height: 40px;
  }
  .lines {
    &::before,
    &::after {
      background-color: #000000 !important;
      top: 5px !important;
    }
  }
`;

export const BalanceWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  .balance {
    margin: 0;
    font-size: 14px;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
