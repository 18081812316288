import Footer from "components/Footer";
import Header from "components/Header";
import * as S from "./style";
import LoadSpinner from "components/SpinnerLoading";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import ModalList from "components/CommonModals";
import { useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { userInfoApi } from "api/user";
import {
  getUserInfoData,
  setUserInfoData,
} from "features/userInfo/userInfoSlice";
import RequestWithdrawModal from "components/CommonModals/RequestWithdrawModal";
import { closeRequestWithdraw } from "features/popupManage/popupManageSlice";
import { message } from "antd";
import { socket } from "socket";

interface Props {
  children: JSX.Element;
}

const DefaultLayout = ({ children }: Props) => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const popupManage = useSelector((state: RootState) => state.popupManage);
  const dispatch: AppDispatch = useAppDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const getUserInfo = async () => {
    try {
      const resp: any = await userInfoApi();
      if (resp?.result) {
        dispatch(setUserInfoData(resp.data));
      }
    } catch (error) {
      console.log("getUserInfo error", error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    const handleSocketMessages = async (data: any) => {
      console.log("📩 Received message from server:", data);
      try {
        const resp: any = await userInfoApi();
        if (resp?.result && data?.data?.data?.user_id === resp?.data?.id) {
          if (data?.data?.code === 200) {
            messageApi.open({
              type: "success",
              content: data?.data?.message,
            });
            dispatch(getUserInfoData());
          }
        }
      } catch (error) {
        console.error("Error fetching user info in socket handler:", error);
      }
    };

    const handleConnectError = (err: any) => {
      console.error("Socket connection error:", err.message);
      console.error("Additional error description:", err.description);
      console.error("Error context:", err.context);
    };

    // Add socket event listeners
    socket.on("BUY_COIN_TRADER_TRANSFER_TO_USER", handleSocketMessages);
    socket.on("connect_error", handleConnectError);

    // Cleanup on component unmount
    return () => {
      socket.off("BUY_COIN_TRADER_TRANSFER_TO_USER", handleSocketMessages);
      socket.off("connect_error", handleConnectError);
    };
  }, [dispatch, messageApi]);

  return (
    <S.Wrapper>
      {contextHolder}
      <ModalList />
      <Header />
      {isLoading && <LoadSpinner isOverlay />}
      <S.Body>{children}</S.Body>
      <RequestWithdrawModal
        open={popupManage?.isOpenRequestWithdraw}
        // handleOk={() => getWithdrawData()}
        handleCancel={() => dispatch(closeRequestWithdraw())}
        onCancel={() => dispatch(closeRequestWithdraw())}
      />
      {/* <Footer /> */}
    </S.Wrapper>
  );
};

export default DefaultLayout;
