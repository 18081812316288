import { Table } from "antd";
import type { TablePaginationConfig, TableProps } from "antd";
import { getDataWithdraw } from "api/my-page";
// import dayjs from "dayjs";
import { TableParams } from "pages/types";
import { useEffect, useState } from "react";
import { MoneyConvert } from "utils/format";
// import RequestWithdrawModal from "components/CommonModals/RequestWithdrawModal";
// import { useSelector } from "react-redux";
// import { AppDispatch, RootState } from "app/store";
// import { useAppDispatch } from "app/hooks";
// import { closeRequestWithdraw } from "features/popupManage/popupManageSlice";

interface DataType {
  id: number;
  amount: string;
  company_address: string;
  create_time: string;
  transaction_hash: string;
  rate: string | number;
  money: string | number;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "생성시간",
    key: "create_time",
    dataIndex: "create_time",
    render: (_, record) => (
      <p className="table-text">
        {record.create_time.replace("T", " ").replace(".000Z", "")}
      </p>
    ),
    // width: 200,
  },
  {
    title: "테더시세",
    dataIndex: "rate",
    key: "rate",
    render: (_, record) => (
      <p className="table-text">
        {MoneyConvert(Number(record.rate || 0), true, 2)} oUSDT
      </p>
    ),
    // width: 200,
  },
  {
    title: "출금금액",
    dataIndex: "money",
    key: "money",
    render: (_, record) => (
      <p className="table-text">
        {MoneyConvert(Number(record.money || 0), true, 0)}원
      </p>
    ),
    // width: 200,
  },
  {
    title: "출금수량",
    dataIndex: "amount",
    key: "amount",
    render: (_, record) => (
      <p className="table-text">
        {MoneyConvert(Number(record.amount || 0), true, 2)} oUSDT
      </p>
    ),
    // width: 200,
  },
  // {
  //   title: "업체 지갑주소",
  //   dataIndex: "company_address",
  //   key: "company_address",
  //   render: (_, record) => (
  //     <p className="table-text">{record.company_address}</p>
  //   ),
  //   // width: 200,
  // },
  {
    title: "TXID",
    key: "TXID",
    dataIndex: "TXID",
    render: (_, record) => (
      // <p className="table-text">{record.transaction_hash_pa}</p>
      <a
        target="_blank"
        href={`https://klaytnscope.com/tx/${record.transaction_hash}?tabId=tokenTransfer`}
        className="table-text txid"
      >
        {record.transaction_hash}
      </a>
    ),
  },
];

const DataWithdraw = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [totalElement, setTotalElement] = useState<number>(0);

  const getWithdrawData = async () => {
    try {
      const resp: any = await getDataWithdraw({
        page: tableParams?.pagination?.current || 1,
        perPage: tableParams?.pagination?.pageSize || 5,
        query: "",
        startTime: "",
        endTime: "",
      });
      // console.log("getDataBuy resp", resp);
      if (resp?.result) {
        const dataResp = resp?.data?.items?.map((item: any, index: number) => {
          return {
            ...item,
            id:
              index +
              1 +
              ((tableParams?.pagination?.current || 1) - 1) *
                (tableParams?.pagination?.pageSize || 5),
          };
        });
        setData(dataResp || []);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            // total: resp?.data?.pageable?.totalElement,
          },
        });
        setTotalElement(resp?.data?.pageable?.totalElement);
      }
    } catch (error) {
      console.log("getDataBuy error", error);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 5,
        },
      });
    }
  };

  useEffect(() => {
    getWithdrawData();
  }, [JSON.stringify([tableParams])]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    // console.log("handleTableChange", pagination?.current);

    setTableParams({
      pagination: pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  // console.log(
  //   "popupManage?.isOpenRequestWithdraw",
  //   popupManage?.isOpenRequestWithdraw
  // );

  return (
    <div>
      <Table
        rowKey="id"
        className="cs-table"
        columns={columns}
        dataSource={data}
        scroll={{ x: "max-content" }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: tableParams?.pagination?.pageSize,
          current: tableParams?.pagination?.current,
          total: totalElement,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default DataWithdraw;
