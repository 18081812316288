import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import logo from "assets/images/logo.svg";
import * as S from "./style";
import { useAuth } from "customHook/useAuth";
import { Button, message } from "antd";
import { logout } from "features/user/userSlice";
import { useAppDispatch } from "app/hooks";
import { openLoading, closeLoading } from "features/loading/loadingSlice";
import { MoneyConvert } from "utils/format";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import WalletIcon from "assets/images/Wallet.svg";
import UsdtIcon from "assets/images/USDT_icon.svg";
import FileIcon from "assets/images/FileText.svg";
import { openRequestWithdraw } from "features/popupManage/popupManageSlice";
// import Guideline from "assets/pdf/구매대행_1_Client_Guideline_2025_01_04_회원가이드_PDF.pdf";

// var scroller = Scroll.scroller;
// import { Link, useLoaderData } from "react-router-dom";

function Header() {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  // const [dataUserInfo, setDataUserInfo] = useState<any>();
  const { width }: any = useWindowSize();
  const { isLogined } = useAuth();
  const dispatch: AppDispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const { userInfoData, exchangeData }: any = useSelector(
    (state: RootState) => state.userInfo
  );

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShowMenuMobile = () => {
    if (width <= 1024) {
      setIsOpenMenu(!isOpenMenu);
    }
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const position = element.offsetTop;
      scroll.scrollTo(position, {
        spy: true,
        smooth: true,
        offset: -50,
        duration: 500,
      });
    }
  };

  const handleClickLogo = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const menuItems = [
    // { path: "/terms-of-use", label: "이용약관" },
    // { path: "/privacy-policy", label: "개인정보 처리방침" },
    ...(isLogined
      ? [
          { path: "/purchase", label: "간편구매" },
          { path: "", label: "코인전송" },
          { path: "/sell", label: "간편판매" },
          { path: "/mypage", label: "마이페이지" },
          { path: "/guideline", label: "메뉴얼" },
        ]
      : []),
    // { path: "/inquire", label: "고객지원" },
  ];

  // console.log("MoneyConvert ", MoneyConvert("13.568521", true));

  return (
    <S.Wrapper>
      {contextHolder}
      <header
        id="header"
        className={`submenu-light ${
          width > 1024 && scrollPosition > 100 ? "header-sticky" : "dark"
        } ${width > 1024 && scrollPosition > 200 ? "sticky-active" : "dark"}`}
        style={{
          backgroundColor: `${
            location.pathname === "/" ? "#03000A" : "transparent"
          }`,
        }}
      >
        <div className="header-inner">
          <div className="container">
            {/*Logo*/}
            <div id="logo" onClick={handleClickLogo}>
              <Link to="/">
                <span className="logo-default">
                  <img alt="logo" src={logo} height={30} />
                </span>
              </Link>
            </div>
            <div id="mainMenu-trigger">
              <a onClick={handleShowMenuMobile} className="lines-button x">
                <span className="lines" />
              </a>
            </div>
            {/*end: Navigation Resposnive Trigger*/}
            {/*Navigation*/}
            {width <= 1024 && (
              <div
                className={`over-layout-menu ${isOpenMenu ? "active" : ""}`}
                onClick={() => setIsOpenMenu(false)}
              />
            )}
            <S.BoxMenu id="mainMenu" className={isOpenMenu ? "open-menu" : ""}>
              <div className="container">
                {width <= 1024 && (
                  <S.CloseMenu>
                    <a
                      onClick={() => setIsOpenMenu(false)}
                      className="lines-button x toggle-active"
                    >
                      <span className="lines" />
                    </a>
                  </S.CloseMenu>
                )}

                <nav>
                  <ul>
                    {menuItems.map((item, index) => {
                      if (item.label === "코인전송") {
                        return (
                          <li
                            key={index}
                            className={`menu-item ${
                              location.pathname === item.path
                                ? "menu-item-active"
                                : ""
                            }`}
                            onClick={() => setIsOpenMenu(false)}
                          >
                            <button
                              onClick={() => dispatch(openRequestWithdraw())}
                            >
                              {item.label}
                            </button>
                          </li>
                        );
                      }
                      return (
                        <li
                          key={index}
                          className={`menu-item ${
                            location.pathname === item.path
                              ? "menu-item-active"
                              : ""
                          }`}
                          onClick={() => setIsOpenMenu(false)}
                        >
                          <Link to={item.path}>{item.label}</Link>
                        </li>
                      );
                    })}
                    {width <= 1024 && (
                      <>
                        {isLogined ? (
                          <>
                            <li>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Button
                                  className="btn btn-danger btn-rounded"
                                  style={{
                                    color: "#ffffff",
                                    fontSize: 14,
                                    height: 50,
                                    padding: "9px 36px",
                                  }}
                                  onClick={() => {
                                    dispatch(logout());
                                    dispatch(openLoading());
                                    messageApi.open({
                                      type: "success",
                                      content: "로그아웃 성공했습니다!",
                                      duration: 2,
                                      onClose: () => {
                                        dispatch(closeLoading());
                                      },
                                    });
                                  }}
                                >
                                  로그아웃
                                </Button>
                                {/* <a
                                  className="btn-v4 btn"
                                  download
                                  href={Guideline}
                                >
                                  <img src={FileIcon} alt="download" />
                                </a> */}
                              </div>
                            </li>
                            {/* <li>
                              <p className="balance">
                                <img src={UsdtIcon} alt="" width="18px" />{" "}
                                {MoneyConvert(
                                  Number(exchangeData?.rate_krw_usdt_auto) || 0,
                                  true
                                )}
                                oUSDT
                              </p>
                            </li> */}
                            <li>
                              <p className="balance">
                                잔고:{" "}
                                {MoneyConvert(
                                  Number(
                                    Number(userInfoData?.balance) *
                                      exchangeData?.rate_krw_usdt
                                  ) || 0,
                                  true,
                                  0
                                )}
                                KRW
                              </p>
                            </li>
                            <li>
                              <p className="balance">
                                {MoneyConvert(
                                  Number(userInfoData?.balance) || 0,
                                  true,
                                  2
                                )}
                                oUSDT
                              </p>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link
                                to="/login"
                                className="btn btn-v01 btn-rounded"
                                style={{ color: "#ffffff", fontSize: 14 }}
                              >
                                로그인
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/register"
                                className="btn btn-v02 btn-rounded"
                                style={{ color: "#03000A", fontSize: 14 }}
                              >
                                회원가입
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    )}
                  </ul>
                </nav>
              </div>
            </S.BoxMenu>
            {width > 1024 && (
              <div className="grp-btn">
                {isLogined ? (
                  <S.BalanceWrapper>
                    {/* <p className="balance">
                      <img src={UsdtIcon} alt="" width="18px" />{" "}
                      {MoneyConvert(
                        Number(exchangeData?.rate_krw_usdt_auto) || 0,
                        true
                      )}{" "}
                      oUSDT
                    </p> */}
                    <p
                      className="balance"
                      style={{
                        border: "1px #ffffff solid",
                        borderRadius: 20,
                        padding: "4px 18px",
                      }}
                    >
                      <img src={WalletIcon} alt="" width="18px" />{" "}
                      {MoneyConvert(
                        Number(userInfoData?.balance) *
                          exchangeData?.rate_krw_usdt || 0,
                        true,
                        0
                      )}
                      KRW /{" "}
                      {MoneyConvert(
                        Number(userInfoData?.balance) || 0,
                        true,
                        2
                      )}{" "}
                      oUSDT
                    </p>
                    <Button
                      className="btn btn-v01 btn-rounded"
                      style={{ fontSize: 14, height: 40, padding: "9px 36px" }}
                      onClick={() => {
                        dispatch(logout());
                        dispatch(openLoading());
                        messageApi.open({
                          type: "success",
                          content: "로그아웃 성공했습니다!",
                          duration: 2,
                          onClose: () => {
                            dispatch(closeLoading());
                          },
                        });
                      }}
                    >
                      로그아웃
                    </Button>
                    {/* <a className="btn-v4 btn" download href={Guideline}>
                      <img src={FileIcon} alt="download" />
                    </a> */}
                  </S.BalanceWrapper>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-v01 btn-rounded"
                      style={{ fontSize: 14, padding: "12px 36px" }}
                    >
                      로그인
                    </Link>
                    <Link
                      to="/register"
                      className="btn btn-v02 btn-rounded"
                      style={{
                        color: "#03000A",
                        fontSize: 14,
                        padding: "12px 36px",
                      }}
                    >
                      회원가입
                    </Link>
                  </>
                )}
              </div>
            )}
            {/*end: Navigation*/}
          </div>
        </div>
      </header>
    </S.Wrapper>
  );
}

export default Header;
