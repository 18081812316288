import { apiConfig } from "api";
import { ApiUrl } from "constant/endpoint";

interface ListWithdrawPayload {
  perPage: number;
  page: number;
  query: string;
  startTime: string;
  endTime: string;
}

interface UserToADminDataTransfer {
  company_address: string;
  amount: number | string;
  money: number;
  rate: number;
}

export const getVerifyPhoneHistoryApi = async () => {
  return apiConfig.request({
    url: ApiUrl.VERIFY_PHONE_HISTORY,
    method: "GET",
    tokenRequired: true,
  });
};

export const downloadExcelUserHistoryApi = async () => {
  return apiConfig.request({
    url: ApiUrl.DOWNLOAD_EXCEL_USER_HISTORY,
    method: "GET",
    tokenRequired: true,
    responseType: "blob",
  });
};

export const getDataWithdraw = (payload: ListWithdrawPayload) => {
  return apiConfig.request({
    url: ApiUrl.GET_WITHDRAW,
    method: "POST",
    data: payload,
    tokenRequired: true,
  });
};

export const userTransferToAdmin = (payload: UserToADminDataTransfer) => {
  return apiConfig.request({
    url: ApiUrl.USER_TRANFER_TO_ADMIN,
    method: "POST",
    data: payload,
    tokenRequired: true,
  });
};
