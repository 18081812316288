import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { getRateKrwUsdtApi, userInfoApi } from "api/user";

export interface UserInfoState {
  userInfoData: any;
  exchangeData: any;
}

const initialState: UserInfoState = {
  userInfoData: null,
  exchangeData: null,
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfoData: (state, action) => {
      state.userInfoData = action.payload;
    },
    setExchangeData: (state, action) => {
      state.exchangeData = action.payload;
    },
  },
});

export const { setUserInfoData, setExchangeData } = userInfoSlice.actions;

export const userDataInfoSelect = (state: RootState) => state.userInfo;

export const getUserInfoData = (): AppThunk => async (dispatch) => {
  try {
    const response: any = await userInfoApi();
    console.log("response", response);
    if (response?.result) {
      dispatch(setUserInfoData(response?.data));
    }
  } catch (error) {
    console.log("getUserInfoData error", error);
  }
};

export const getDataExchange = (): AppThunk => async (dispatch) => {
  try {
    const response: any = await getRateKrwUsdtApi();
    console.log("response", response);
    if (response?.result) {
      dispatch(setExchangeData(response?.data));
    }
  } catch (error) {
    console.log("getDataExchange error", error);
  }
};

export default userInfoSlice.reducer;
