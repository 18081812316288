import React, { useEffect, useState } from "react";
import * as S from "./style";
import { Link, useHistory } from "react-router-dom";
import { getAddressSellCoinApi, userInfoApi } from "api/user";
import {
  openApproveWarning,
  openLockBuySell,
} from "features/popupManage/popupManageSlice";
import { useDispatch } from "react-redux";

function Sell() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [addressSellCoin, setAddressSellCoin] = useState<string>("");

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const resp: any = await userInfoApi();
      if (resp?.result) {
        if (resp?.data?.approve === 0) {
          dispatch(openApproveWarning());
        } else {
          if (resp?.data?.lock_sell_coin === 1) {
            dispatch(openLockBuySell());
          }
        }
      }
    } catch (error) {
      console.log("User Info error", error);
    }
  };

  const gotoSellForm = () => {
    if (isChecked) {
      history.push({ pathname: "/sell-form", state: addressSellCoin });
    }
  };

  const getAddressSell = async () => {
    try {
      const resp: any = await getAddressSellCoinApi();
      if (resp?.result) {
        setAddressSellCoin(resp?.data);
      }
    } catch (error) {
      console.log("getAddressSell error", error);
    }
  };

  useEffect(() => {
    getAddressSell();
  }, []);

  return (
    <React.Fragment>
      <S.Wrapper>
        <div className="container sub-page">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-text heading-section">
                <h2>oUSDT 판매 신청</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="sub-tit">
                <p className="lead">
                  다음의 유의사항을 읽으시고 확인, 이해, 동의 하신다면 진행
                  부탁드립니다.
                </p>
              </div>
              <div className="agree_cont">
                <ul className="dot_list">
                  <li>송금 완료 이후 거래 취소는 불가능합니다.</li>
                  <li style={{ color: "#FF3C00" }}>
                    사기 또는 보이스피싱이 의심되는 경우에 한해서 현금입금이
                    72시간 지연전송됨을 알려드립니다
                  </li>
                  <li>
                    송금된 코인은 수수료가 자동 차감되어 요청하신 계좌로
                    계좌이체 됩니다.
                  </li>
                  <li>
                    수수료에는 고객님이 부담하셔야 하는 송금 수수료와 가상화폐
                    거래 수수료가 포함되어 있습니다.
                  </li>
                  <li>
                    모든 코인 송금 후 3승인 이후, 거래소 지갑에 반영된 후
                    처리됩니다.
                  </li>
                  <li>
                    승인 시간, 거래소 지갑에 반영되는 시간은 블록체인 네트워크
                    상황, 거래소 서버 상황에 따라 15분 ~ 3시간 이상 걸릴 수
                    있습니다.
                  </li>
                  <li>원화는 대표자 이름으로 입금됩니다.</li>

                  <li>
                    {" "}
                    신청하기 전에{" "}
                    <Link to="/terms-of-use" target="_blank">
                      이용약관
                    </Link>{" "}
                    및{" "}
                    <Link to="/privacy-policy" target="_blank">
                      개인정보 처리방침을
                    </Link>{" "}
                    확인하시기 바랍니다.
                  </li>
                  <li>기타 코인 판매는 상담 바랍니다. 텔레그램: </li>
                  <li>
                    송금하실 oUSDT 전자지갑 주소 : <br />
                    {addressSellCoin}
                  </li>
                </ul>
              </div>
              <div className="form-group mb-1 mt-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="reminders"
                    id="reminders"
                    className="custom-control-input"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    required
                  />
                  <label className="custom-control-label" htmlFor="reminders">
                    상기 내용을 확인했습니다.
                  </label>
                </div>
                <div className="t-a-c">
                  <button
                    onClick={gotoSellForm}
                    type="button"
                    className="btn btn-v01 btn-rounded go_purchase"
                    disabled={!isChecked}
                    style={{
                      height: 65,
                      width: 315,
                      fontSize: 18,
                      padding: "27px 18px",
                      marginTop: 48,
                    }}
                  >
                    판매신청
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Sell;
